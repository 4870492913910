import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

  	private fooSubject = new Subject<any>();
  	private initSubject = new Subject<any>();
    private homeSubject = new Subject<any>();

    publishSomeData(data: any) {
        this.fooSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.fooSubject;
    }

    publishInit(data: any) {
        this.initSubject.next(data);
    }

    getObservableInit(): Subject<any> {
        return this.initSubject;
    }

    publishHome(data: any) {
        this.homeSubject.next(data);
    }

    getObservableHome(): Subject<any> {
        return this.homeSubject;
    }
}
